const galleryImages = [
  {
    src: "1"
  },
  { src: "2" },
  { src: "3" },
  { src: "4" },
  { src: "5" },
  { src: "6" },
  { src: "7" },
  { src: "8" },
  { src: "9" },
  { src: "10" },
  { src: "11" },
  { src: "12" },
  { src: "13" },
  { src: "14" },
  { src: "15" },
  { src: "16" },
  { src: "17" },
  { src: "18" },
  { src: "19" },
  { src: "20" },
  { src: "21" },
  { src: "22" },
  { src: "23" },
  { src: "24" },
  { src: "25" },
  { src: "26" },
  { src: "27" },
  { src: "28" },
  { src: "29" },
  { src: "30" },
  { src: "31" },
  { src: "32" },
  { src: "33" },
  { src: "34" },
  { src: "35" },
  { src: "36" },
  { src: "37" }
];


export default galleryImages;
